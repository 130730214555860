import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import FileUploader from "./FileUploader";
import TextField from "@material-ui/core/TextField";

const useStyle = makeStyles((theme) => ({
  button: {
    borderRadius: "20px",
  },
  inputVideo: {
    display: "none",
  },
  formField: {
    backgroundColor: theme.palette.background.form,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "& label": {
      color: "#9FA2B4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#C637A0",
      },
    },
  },
}));

export default (props) => {
  const classes = useStyle();

  //TODO Unir componente FileUploader e CircularProgressWithLabel em um unico componente e chamar no Form.
  var config = {
    onUploadProgress: function (progressEvent) {
      var uploadProgress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
  };

  function onChargeVideo(e) {
    const URL = "http://christibodofit.institutgrassetinfo.com:4030/upload";
    let file = e.target.files[0];
    const formData = new FormData();

    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(URL, formData, config)
      .then((response) => {
        alert("La vidéo a été téléversé avec succès.");
        const videoAdress = `/videos/${response.data.message}`;
        props.onHandleVideoAddress(videoAdress);
        console.log(response);
      })
      .catch((error) => {});

    // const URL = "http://christibodofit.institutgrassetinfo.com:4030/upload";
    // let file = e.target.files[0];
    // console.log(file);

    // let reader = new FileReader();
    // reader.readAsDataURL(file);

    // reader.onload = (e) => {
    //   console.warn("data file", e.target.result);
    //   const video = { file: e.target.result };

    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // };

    //   return axios
    //     .post(URL, video, config)
    //     .then((response) => {
    //       alert("La vidéo a été téléversé avec succès.");
    //       console.warn("Result:", response)
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // };
  }

  return (
    <Box container>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onHandleShowForm()}
          className={classes.button}
        >
          {props.hideForm
            ? "Ajouter un Nouveau Exercice"
            : "Masquer le formulaire"}
        </Button>
      </Box>
      <Box display={props.hideForm ? "none" : "initial"}>
        <Box display="flex" mt={6}>
          <Box flexDirection="column" width="40%" mr={1}>
            <Box>
              <TextField
                label="Titre"
                variant="outlined"
                size="small"
                fullWidth
                name="name"
                value={props.exercice.name}
                onChange={props.onHandleInputExerciceChange}
                className={classes.formField}
              />
            </Box>
            <Box>
              <TextField
                label="Instructions"
                variant="outlined"
                size="small"
                fullWidth
                name="instruction"
                value={props.exercice.instruction}
                onChange={props.onHandleInputExerciceChange}
                className={classes.formField}
              />
            </Box>
          </Box>
          <Box width="60%" ml={1}>
            <TextField
              label="Description"
              variant="outlined"
              size="small"
              multiline
              fullWidth
              rows={7}
              name="description"
              value={props.exercice.description}
              onChange={props.onHandleInputExerciceChange}
              className={classes.formField}
            />
          </Box>
        </Box>

        <Box display="flex" mt={1}>
          <Box mr={2}>
            {/* <FileUploader onHandleVideoAddress={handleVideoAddress} /> */}
            <input
              id="contained-button-file"
              type="file"
              name="file"
              accept="video/*"
              onChange={(e) => onChargeVideo(e)}
              className={classes.inputVideo}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
              >
                TÉLÉVERSER VIDEO
              </Button>
            </label>
            {/* 
            <label className={classes.label}>
              TÉLÉVERSER VIDEO
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
              />
            </label>

            <Button
              type="file"
              variant="contained"
              color="primary"
              onClick=""
              className={classes.button}
            >
              TÉLÉVERSER VIDEO
            </Button> */}
          </Box>
          <Box>
            {/* <CircularProgressWithLabel value={uploadData.uploadProgress} /> */}
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onHandleAddExercice}
              className={classes.button}
            >
              Sauvegarder
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
