import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "40vw",
    height: "100vh",
  },
  tableCellHeader: {
    color: "#9FA2B4",
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.44,
  },
  tableCellBodyPrograms: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: 0.44,
  },
  tableCellBodyCustomers: {
    color: "#5E6366",
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: 0.15,
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head" className={classes.tableCellHeader}>
              Programmes
            </TableCell>
            <TableCell
              variant="head"
              align="right"
              className={classes.tableCellHeader}
            >
              Clients
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.programsData.map((data, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCellBodyPrograms}>
                {data.programName}
              </TableCell>
              <TableCell
                align="right"
                className={classes.tableCellBodyCustomers}
              >
                {data.totalUsers}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
