import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router";

import Home from "../components/home/Home";
import Customers from "../components/customers/Customers";
import Programs from "../components/programs/Programs";
import Exercice from "../components/programs/exercices/Exercice";
import Parameters from "../components/parameters/Parameters";

export default (props) => {
  const [selectedProgram, setSelectedProgram] = useState();

  function accessProgramExercises(program) {
    setSelectedProgram(program);
  }

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/clients" component={Customers} />
      <Route exact path="/programmes">
        <Programs onAccessProgramExercises={accessProgramExercises} />
      </Route>
      <Route path="/programmes/exercices">
        <Exercice selectedProgram={selectedProgram} />
      </Route>
      <Route path="/parametres">
        <Parameters userEmail={props.userEmail} />
      </Route>
      <Redirect from="*" to="/" />
    </Switch>
  );
};
