import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "40vw",
    height: "100vh",
  },
  tableCellHeader: {
    color: "#9FA2B4",
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.44,
  },
  tableCellBodyName: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: 0.44,
  },
  tableCellBodyIdProgram: {
    color: "#5E6366",
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: 0.15,
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head" className={classes.tableCellHeader}>
              Nom
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              ID
            </TableCell>
            <TableCell
              variant="head"
              align="right"
              className={classes.tableCellHeader}
            >
              Programme
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.customersData.map((customer, id) => (
            <TableRow key={id}>
              <TableCell className={classes.tableCellBodyName}>
                {`${customer.firstName} ${customer.lastName}`}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCellBodyIdProgram}
              >
                {customer.id}
              </TableCell>
              <TableCell
                align="right"
                className={classes.tableCellBodyIdProgram}
              >
                {customer.programsName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
