import React, { useState } from "react";
import axios from "axios";

import App from "./App";
import Auth from "../auth/Auth";

export default (props) => {
  const [validateToken, setValidateToken] = useState("");
  const [statusResponse, setStatusResponse] = useState(403);
  const [userEmail, setUserEmail] = useState("")

  axios.defaults.baseURL = "https://christibodo-fit.herokuapp.com";
  axios.defaults.headers.common["Authorization"] =
    "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ0aWFnby5wZXJlaXJhQGdtYWlsLmNvbSIsImV4cCI6MTYwNTMxODk3MH0.wDTdqIl-ZEDe7oE5uJH86gPtsoBDdip3mbuNjJZ4gSy1HtD16MQSODq80X9O-s_AoHyc7ATQ8iKyZq7QqTrgvg";

  function login(login) {    
    setUserEmail(login.email)

    axios
      .post("/login", login)
      .then((resp) => {
        setValidateToken(resp.headers.authorization);
        setStatusResponse(resp.status);
        axios.defaults.headers.common["Authorization"] =
          resp.headers.authorization;
      })
      .catch(() => alert("Nom d'utilisateur ou mot de passe invalide!"));
  }

  if (statusResponse === 200 && validateToken !== undefined) {
    // if (true) {
    return <App userEmail={userEmail} />;
  } else {
    return <Auth onLogin={login} />;
  }
};
