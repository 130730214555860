import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles((theme) => ({
  tableCellHeader: {
    color: "#9FA2B4",
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.44,
  },
  tableCellBodyName: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: 0.44,
    textDecoration: "none",
  },
  tableCellBodyProgram: {
    color: "#5E6366",
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: 0.15,
  },
  iconButton: {
    backgroundColor: "#DDE2FF",
  },
}));

export default (props) => {
  const classes = useStyles();

  function levelInFrench(level) {
    if (level === "BEGINNER") {
      return "Débutante";
    }
    if (level === "INTERMEDIATE") {
      return "Intermédiaire";
    }
    if (level === "ADVANCED") {
      return "Avancée";
    }
    return "";
  }

  function dateFormat(dateString) {
    return dateString.substring(0, 10);
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head" className={classes.tableCellHeader}>
              Titre
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              ID
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              Prix
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              Niveau
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              Date
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              Éditer
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              Supprimer
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData.map((program, id) => (
            <TableRow key={id}>
              <TableCell
                onClick={() => props.onAccessProgramExercises(program)}
                component={Link}
                to="/programmes/exercices"
                className={classes.tableCellBodyName}
              >
                {program.name}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCellBodyProgram}
              >
                {program.id}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCellBodyProgram}
              >
                {program.price}$ CA
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCellBodyProgram}
              >
                {levelInFrench(program.level)}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCellBodyProgram}
              >
                {dateFormat(program.creationDate)}
              </TableCell>
              <TableCell align="center">
                <IconButton
                  color="primary"
                  onClick={() => props.onHandleEditProgram(program.id)}
                  className={classes.iconButton}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  color="primary"
                  onClick={() => props.onHandleDeleteProgram(program.id)}
                  className={classes.iconButton}
                >
                  <ClearIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
