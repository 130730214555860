import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 258,
    height: 134,
    border: "2px solid #C637A0",
    borderRadius: 8,
  },
  cardContent: {
    display: "inline",
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: 19,
    letterSpacing: 0.4,
    textAlign: "center",
  },
  cardQty: {
    fontWeight: 700,
    fontSize: 40,
    letterSpacing: 1,
    textAlign: "center",
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography color="primary">
          <Box className={classes.cardTitle}>{props.cardTitle}</Box>
          <Box className={classes.cardQty}>{props.cardQty}</Box>
        </Typography>
      </CardContent>
    </Card>
  );
};
