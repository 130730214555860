import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Main from "../templates/Main";
import Table from "./Table";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default (props) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  const loadTableData = useCallback(() => {
    axios
      .get("/usersSimplified")
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <Main title="Clients">
      <Grid item xs={12} className={classes.root}>
        <Box display="flex" m={1}>
          <Table customersData={tableData} />
        </Box>
      </Grid>
    </Main>
  );
};
