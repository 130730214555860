import React from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import HomeIcon from "@material-ui/icons/Home";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
  },
  list: {
    width: "100%",
  },
  divider: {
    background: "rgba(223, 224, 235, 0.2)",
  },
  listItemIcon: {
    color: "#9FA2B4",
    opacity: 0.4,
  },
  listItemText: {
    color: "#A4A6B3",
    fontWeight: 400,
    letterSpacing: 0.2,
    lineHeight: 18,
    fontSize: "1.2em",
  },
  listItem: {
    marginTop: 5,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "rgba(198, 55, 160, 0.08)",
      "& $listItemIcon": {
        color: "#DDE2FF",
        opacity: 1,
      },
      "& $listItemText": {
        color: "#DDE2FF",
      },
    },
  },
}));

export default (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.root}>
      <List component="nav" className={classes.list}>
        <ListItem button component={Link} to="/" className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Accueil" className={classes.listItemText} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/clients"
          className={classes.listItem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Clients" className={classes.listItemText} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/programmes"
          className={classes.listItem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <FitnessCenterIcon />
          </ListItemIcon>
          <ListItemText
            primary="Programmes"
            className={classes.listItemText}
            useTheme={theme.teste}
          />
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem
          button
          component={Link}
          to="/parametres"
          className={classes.listItem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Paramètres" className={classes.listItemText} />
        </ListItem>
      </List>
    </Box>
  );
};
