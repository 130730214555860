import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Main from "../templates/Main";
import Card from "./Card";
import Table from "./Table";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default (props) => {
  const classes = useStyles();
  const [cardsData, setCardsData] = useState({ Programs: 0, Users: 0 });
  const [tableData, setTableData] = useState([]);

  const loadTableData = useCallback(() => {
    axios
      .get("/programs/overview?direction=ASC&orderBy=name")
      .then((response) => {
        setTableData(response.data.content);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const loadCardsData = useCallback(() => {
    axios
      .get("/overall/overview")
      .then((response) => {
        setCardsData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    loadTableData();
    loadCardsData();
  }, [loadTableData, loadCardsData]);

  return (
    <Main title="Accueil">
      <Grid item xs={12} className={classes.root}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" m={3}>
            <Box mr={2}>
              <Card cardTitle="Programmes" cardQty={cardsData.Programs} />
            </Box>
            <Box ml={2}>
              <Card cardTitle="Clients" cardQty={cardsData.Users} />
            </Box>
          </Box>
          <Box m={3} mt={6}>
            <Table programsData={tableData} />
          </Box>
        </Box>
      </Grid>
    </Main>
  );
};
