import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const useStyle = makeStyles((theme) => ({
  button: {
    borderRadius: "20px",
  },
  formField: {
    backgroundColor: theme.palette.background.form,
    width: "36ch",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "& label": {
      color: "#9FA2B4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#C637A0",
      },
    },
  },
}));

export default (props) => {
  const classes = useStyle();

  return (
    <Box container>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onHandleShowForm()}
          className={classes.button}
        >
          {props.hideForm
            ? "Créer un Nouveau Programme"
            : "Masquer le formulaire"}
        </Button>
      </Box>

      <Box display={props.hideForm ? "none" : "initial"}>
        <Box container flexWrap="wrap" mt={6}>
          <TextField
            label="Titre"
            variant="outlined"
            size="small"
            name="name"
            value={props.program.name}
            onChange={props.onHandleInputProgramChange}
            className={classes.formField}
          />
          <TextField
            label="Prix"
            variant="outlined"
            size="small"
            type="number"
            name="price"
            value={props.program.price}
            onChange={props.onHandleInputProgramChange}
            className={classes.formField}
          />
          <TextField
            label="Niveau"
            variant="outlined"
            size="small"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ native: true }}
            name="level"
            value={props.program.level}
            onChange={props.onHandleInputProgramChange}
            className={classes.formField}
          >
            <option value="DEFAULT"></option>
            <option value="BEGINNER">Débutante</option>
            <option value="INTERMEDIATE">Intermédiaire</option>
            <option value="ADVANCED">Avancée</option>
          </TextField>
        </Box>
        <Box mt={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={props.onHandleSaveProgram}
            className={classes.button}
          >
            Sauvegarder
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
