import React from "react";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  iconButton: {
    backgroundColor: "#FFFFFF",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <IconButton
          color="primary"
          size="medium"
          onClick=""
          className={classes.iconButton}
        >
          <EditIcon />
        </IconButton>
      }
    >
      <Avatar
        alt={props.userName}
        src={localStorage.getItem("@chrisTibodo/userPhoto")}
        className={classes.avatar}
      />
    </Badge>
  );
};
