import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "64px",
    backgroundColor: "#363740",
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <FiberManualRecordIcon color="primary" fontSize="large" />
      <Typography color="primary" variant="h6">
        <Box fontWeight={700} fontSize={19} letterSpacing={0.7}>
          Chris Tibodo
        </Box>
      </Typography>
    </Box>
  );
};
