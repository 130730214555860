import React from 'react';
import ReactDOM from 'react-dom';
import App from './main/App';
import AuthOrApp from "./main/AuthOrApp"
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    {/* <App /> */}
    <AuthOrApp />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
