import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles((theme) => ({
  tableCellHeader: {
    color: "#9FA2B4",
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.44,
    // width:"10%",
  },
  tableHeaderColumnExercices: {
    width: "20%",
  },
  tableHeaderColumnInstruction: {
    width: "20%",
  },
  tableCellBodyFirstColumn: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: 0.44,
  },
  tableCellBodyOtherColumns: {
    color: "#5E6366",
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: 0.15,
  },
  iconButton: {
    backgroundColor: "#DDE2FF",
  },
}));

export default (props) => {
  const classes = useStyles();

  //TODO fazer 2 inputs entre Titre e Instructions par incluir repetições e series

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              variant="head"
              className={`${classes.tableCellHeader} ${classes.tableHeaderColumnExercices}`}
            >
              Exercices
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              ID
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              Description
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={`${classes.tableCellHeader} ${classes.tableHeaderColumnInstruction}`}
            >
              Instruction
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              Éditer
            </TableCell>
            <TableCell
              variant="head"
              align="center"
              className={classes.tableCellHeader}
            >
              Supprimer
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData.map((exercice, id) => (
            <TableRow key={id}>
              <TableCell className={classes.tableCellBodyFirstColumn}>
                {exercice.name}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCellBodyOtherColumns}
              >
                {exercice.id}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCellBodyOtherColumns}
              >
                {exercice.description}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCellBodyOtherColumns}
              >
                {exercice.instruction}
              </TableCell>
              <TableCell align="center">
                <IconButton
                  color="primary"
                  onClick={() => props.onHandleEditExercice(exercice.id)}
                  className={classes.iconButton}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  color="primary"
                  onClick={() => props.onHandleDeleteExercice(exercice.id)}
                  className={classes.iconButton}
                >
                  <ClearIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
