import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(6),
    width: "30vw",
    borderRadius: "20px",
    backgroundColor: "#363740",
    color: "#A4AEB4",
  },
  formField: {
    marginBottom: theme.spacing(6),

    "& label": {
      color: "#A4AEB4",
    },
    "& .Mui-focused": {
      color: "#A4AEB4",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#A4AEB4",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#A4AEB4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#A4AEB4",
    },
  },
  button: {
    borderRadius: "20px",
  },
  paperLink: {
    color: "#A4AEB4",
    textDecoration: "none",
    fontFamily: "Ubuntu",
    fontWeight: 700,
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <TextField
        label="Mot de passe actuel"
        type="password"
        name="currentPassword"
        value={props.userData.currentPassword}
        onChange={props.onHandleInputParametersChange}
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.formField}
      />
      <TextField
        label="Nouveau mot de passe"
        type="password"
        name="newPassword"
        value={props.userData.newPassword}
        onChange={props.onHandleInputParametersChange}
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.formField}
      />
      <TextField
        label="Répétez le nouveau mot de passe"
        type="password"
        name="newPasswordAgain"
        value={props.userData.newPasswordAgain}
        onChange={props.onHandleInputParametersChange}
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.formField}
      />
      <Box display="flex" justifyContent="center" mb={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onHandleChangePassword}
          className={classes.button}
        >
          Enregistrer
        </Button>
      </Box>
      <Box
        textAlign="center"
        component={Link}
        to=""
        className={classes.paperLink}
      >
        Mot de passe oublié?
      </Box>
    </Paper>
  );
};
