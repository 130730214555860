import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Main from "../templates/Main";
import PaperForm from "./PaperForm";
import Avatar from "./Avatar";

const initialState = {
  userData: {
    userId: "",
    currentPassword: "",
    newPassword: "",
    newPasswordAgain: "",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default (props) => {
  const classes = useStyles();
  const [userData, setUserData] = useState(initialState.userData);

  const loadUserId = useCallback(() => {
    axios.get(`/user?email=${props.userEmail}`).then((response) => {
      setUserData({ ...userData, userId: response.data.id });
    });
  });

  useEffect(() => {
    loadUserId();
  }, [loadUserId]);

  function handleInputParametersChange(event) {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  }

  function handleChangePassword(params) {
    axios.get(`/user?email=${props.userEmail}`).then((response) => {
      setUserData({ ...userData, userId: response.data.id });
      console.log(userData);
    });

    if (userData.newPassword !== userData.newPasswordAgain) {
      return alert("Répétition de mot de passe non valide.")
    } else {

    }

  }

  return (
    <Main title={"Paramètres"}>
      <Grid item xs={12} className={classes.root}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mt={3}>
            <Avatar />
          </Box>
          <Box mt={3}>
            <PaperForm
              userData={userData}
              onHandleInputParametersChange={handleInputParametersChange}
              onHandleChangePassword={handleChangePassword}
            />
          </Box>
        </Box>
      </Grid>
    </Main>
  );
};
