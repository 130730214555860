import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";

const useStyle = makeStyles((theme) => ({
  label: {
    fontFamily: "Ubuntu",
    fontSize: "0.875rem",
    fontWeight: 500,
    padding: "6px 16px",
    margin: "12px 0px",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: 10,
    cursor: "pointer",
  },
}));

// Setup Firebase
firebase.initializeApp({
  apiKey: "AIzaSyBb1eK0fpJmo5ZvCMIuVqQ6cee0mLkr3tY",
  storageBucket: "christibodo.appspot.com",
});

const initialState = {
  filenames: [],
  downloadURLs: [],
  isUploading: false,
  uploadProgress: 0,
};

export default (props) => {
  const classes = useStyle();
  const [uploadData, setUploadData] = useState(initialState);

  const handleUploadStart = () =>
    setUploadData({
      isUploading: true,
      uploadProgress: 0,
    });

  const handleProgress = (progress) =>
    setUploadData({
      uploadProgress: progress,
    });

  const handleUploadError = (error) => {
    setUploadData({
      isUploading: false,
    });
    console.error(error);
  };

  const handleUploadSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref("videos")
      .child(filename)
      .getDownloadURL();

    setUploadData((oldState) => ({
      filenames: [oldState.filenames, filename],
      downloadURLs: [oldState.downloadURLs, downloadURL],
      uploadProgress: 100,
      isUploading: false,
    }));
    props.onHandleVideoAddress(filename);
  };

  return (
    <Box display="flex" mt={1} mr={2}>
      <Box>
        <label className={classes.label}>
          TÉLÉVERSER VIDEO
          <FileUploader
            hidden
            accept="*/*"
            name="uploadVideo"
            randomizeFilename
            storageRef={firebase.storage().ref("videos")}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
            multiple
          />
        </label>
      </Box>
      <Box
        ml={2}
        display={uploadData.uploadProgress === 0 ? "none" : "initial"}
      >
        <CircularProgressWithLabel value={uploadData.uploadProgress} />
      </Box>
    </Box>
  );
};
