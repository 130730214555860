import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#F7F8FC",
  },
  title: {
    flexGrow: 1,
    display: "none",
    color: "#C637A0",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#C5C7CD",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "2ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  divider: {
    color: "#DFE0EB",
    height: 30,
    alignSelf: "center",
  },
  userName: {
    color: "#C637A0",
  },
}));

export default (props) => {
  const classes = useStyles();
  let userPhoto = localStorage.getItem("@chrisTibodo/userPhoto");

  if (userPhoto === null) {
    const userPhotoFireBase = "https://firebasestorage.googleapis.com/v0/b/christibodo.appspot.com/o/profile_pics%2FChris.jpeg?alt=media";
    localStorage.setItem("@chrisTibodo/userPhoto", userPhotoFireBase);
    userPhoto = localStorage.getItem("@chrisTibodo/userPhoto");
  } 

  return (
    <Grid item xs={12} className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title}>
            <Box fontWeight={700} letterSpacing={0.3} fontSize={24}>
              {props.title}
            </Box>
          </Typography>
          <Box className={classes.search}>
            <Box className={classes.searchIcon}>
              <SearchIcon />
            </Box>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className={classes.divider}
          />
          <Typography className={classes.userName}>
            <Box fontWeight={700} letterSpacing={0.2} fontSize={14} mr={2}>
              Chris Tibodo {props.userName}
            </Box>
          </Typography>
          <Avatar alt={props.userName} src={userPhoto}
          //  src={URL_FIREBASE_START + USER_PHOTO + URL_FIREBASE_END}
            />
        </Toolbar>
      </AppBar>
    </Grid>
  );
};
