import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles({
  title: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 600,
    fontSize: "2em",
    letterSpacing: "0.4px",
    color: "#C637A0",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "50px",
    width: "30vw",
    borderRadius: "20px",
    backgroundColor: "#363740",
    color: "#A4AEB4",
  },
  login: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 600,
    letterSpacing: "0.4px",
    color: "#C637A0",
  },
  inputEmail: {
    marginTop: "50px",
    backgroundColor: "#F6F6F6",
    border: "1px solid #E8E8E8",
    borderRadius: "8px",
    color: "#C637A0",
    "& label.Mui-focused": {
      color: "#C637A0",
    },
  },
  inputPwd: {
    backgroundColor: "#F6F6F6",
    border: "1px solid #E8E8E8",
    borderRadius: "8px",
    "& label.Mui-focused": {
      color: "#C637A0",
    },
  },
  button: {
    borderRadius: "20px",
    marginTop: "50px",
    fontFamily: "Ubuntu, sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    background: "#C637A0",
    color: "#FFF",
    "&:hover": { background: "#9B51E0" },
  },
  paperLink: {
    marginTop: "16px",
    fontFamily: "Ubuntu, sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    color: "#C637A0",
  },
});

const initialState = {
  email: "",
  password: "",
  showPassword: false,
};

export default (props) => {
  const classes = useStyles();
  const [values, setValues] = useState(initialState);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      height="100vh"
    >
      <Box m={4}>
        <Typography align="center" className={classes.title}>
          Bienvenue sur l’application
        </Typography>
        <Typography align="center" className={classes.title}>
          {props.email}
          Chris Tibodo
        </Typography>
      </Box>
      <Box>
        <Paper elevation={0} className={classes.paper}>
          <Typography align="center" variant="h6" className={classes.login}>
            Log In
          </Typography>
          <TextField
            variant="filled"
            margin="normal"
            size="small"
            required
            label="Courriel"
            name="email"
            value={values.email}
            onChange={handleChange("email")}
            autoFocus
            className={classes.inputEmail}
            InputProps={{ disableUnderline: true }}
          />
          <FormControl
            className={classes.inputPwd}
            variant="filled"
            margin="normal"
            size="small"
            required
            name="password"
            type="password"
          >
            <InputLabel htmlFor="filled-adornment-password">
              Mot de passe
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              disableUnderline
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            variant="contained"
            onClick={() =>
              props.onLogin({ email: values.email, password: values.password })
            }
            className={classes.button}
          >
            Log In
          </Button>
          {/* <Box
            textAlign="center"
            component={Link}
            to=""
            className={classes.paperLink}
          >
            Mot de passe oublié?
          </Box> */}
        </Paper>
      </Box>
    </Box>
  );
};
