import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createMuiTheme, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import Logo from "../components/templates/Logo";
import Nav from "../components/templates/Nav";
import Routes from "./Routes";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F7F8FC",
  },
  asside: {
    maxWidth: "255px",
    backgroundColor: "#363740",
  },
  content: {
    backgroundColor: "#F7F8FC",
  },
}));

export default (props) => {
  const classes = useStyles();

  const theme = createMuiTheme({
    typography: {
      fontFamily: "Ubuntu, Arial, sans-serif",
    },
    palette: {
      primary: {
        main: "#C637A0",
      },
      background: {
        default: "#F7F8FC",
        form: "#F6F6F6",
      },
    },
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Grid container className={classes.root}>
          <Grid item xs={3} sm={3} md={3} lg={2} xl={2} className={classes.asside}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <Logo />
              </Grid>
              <Grid item xs={12}>
                <Nav />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={10} xl={10} className={classes.content}>
            <Grid container direction="column">
              <Routes userEmail={props.userEmail} />
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </BrowserRouter>
  );
};
