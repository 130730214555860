import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Main from "../../templates/Main";
import Table from "./Table";
import Form from "./Form";

const initialState = {
  exercice: {
    id: 0,
    name: "",
    description: "",
    instruction: "",
    videoAddress: "",
    program_id: 0,
  },
  table: [],
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default (props) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState(initialState.table);
  const [exercice, setExercice] = useState(initialState.exercice);
  const [hideForm, setHideForm] = useState(true);

  const loadTableData = useCallback(() => {
    axios
      .get(`/exercices/${props.selectedProgram.id}`)
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  function handleInputExerciceChange(event) {
    setExercice({ ...exercice, [event.target.name]: event.target.value });
  }

  function handleVideoAddress(adress) {
    var newExercice = exercice;
    newExercice.videoAddress = adress;
    setExercice(newExercice);
  }

  function handleAddExercice() {
    var newExercice = exercice;
    newExercice.program_id = props.selectedProgram.id;

    const urlParameters = {
      method: newExercice.id ? "put" : "post",
      urlEnd: newExercice.id
        ? `/updateExercice/${newExercice.id}`
        : "/insertExercice",
    };

    axios[urlParameters.method](urlParameters.urlEnd, newExercice)
      .then((response) => loadTableData())
      .catch((error) => {
        console.error(error);
      });
    setExercice(initialState.exercice);
  }

  function handleEditExercice(id) {
    var exerciceToModify = tableData.filter((exercice) => exercice.id === id);
    setExercice(exerciceToModify[0]);

    if (hideForm) {
      handleShowForm();
    }
  }

  function handleDeleteExercice(id) {
    axios
      .delete(`/deleteExercice/${id}`)
      .then((response) => loadTableData())
      .catch((error) => {
        console.error(error);
      });
  }

  function handleShowForm() {
    if (hideForm) {
      setHideForm(false);
    } else {
      setHideForm(true);
    }
  }

  return (
    <Main title={props.selectedProgram.name}>
      <Grid item xs={12} className={classes.root}>
        <Box display="flex" flexDirection="column">
          <Box m={1}>
            <Table
              tableData={tableData}
              onHandleEditExercice={handleEditExercice}
              onHandleDeleteExercice={handleDeleteExercice}
            />
          </Box>
          <Box m={3} mt={6}>
            <Form
              exercice={exercice}
              hideForm={hideForm}
              onHandleInputExerciceChange={handleInputExerciceChange}
              onHandleAddExercice={handleAddExercice}
              onHandleShowForm={handleShowForm}
              onHandleVideoAddress={handleVideoAddress}
            />
          </Box>
        </Box>
      </Grid>
    </Main>
  );
};
