import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Main from "../templates/Main";
import Table from "./Table";
import Form from "./Form";

const initialState = {
  program: {
    id: 0,
    name: "",
    price: 0.0,
    level: "",
  },
  table: [],
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default (props) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState(initialState.table);
  const [program, setProgram] = useState(initialState.program);
  const [hideForm, setHideForm] = useState(true);

  const loadTableData = useCallback(async () => {
    const response = await axios.get("/programs").catch((error) => {
      console.error(error);
    });
    const data = response.data;
    setTableData(data);
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  function handleInputProgramChange(event) {
    setProgram({ ...program, [event.target.name]: event.target.value });
  }

  function handleSaveProgram() {
    const newProgram = program;
    const urlParameters = {
      method: newProgram.id ? "put" : "post",
      urlEnd: newProgram.id
        ? `/updateProgram/${newProgram.id}`
        : "/insertPrograms",
    };

    axios[urlParameters.method](urlParameters.urlEnd, newProgram)
      .then((response) => loadTableData())
      .catch((error) => {
        console.error(error);
      });
    setProgram(initialState.program);
  }

  function handleEditProgram(id) {
    const programToModify = tableData.filter((program) => program.id === id);
    setProgram(programToModify[0]);

    if (hideForm) {
      handleShowForm();
    }
  }

  function handleDeleteProgram(id) {
    axios
      .delete(`/deleteProgram/${id}`)
      .then((response) => loadTableData())
      .catch((error) => {
        console.error(error);
      });
  }

  function accessProgramExercises(program) {
    props.onAccessProgramExercises(program);
  }

  function handleShowForm() {
    if (hideForm) {
      setHideForm(false);
    } else {
      setHideForm(true);
    }
  }

  return (
    <Main title="Programmes">
      <Grid item xs={12} className={classes.root}>
        <Box display="flex" flexDirection="column">
          <Box m={1}>
            <Table
              tableData={tableData}
              onAccessProgramExercises={accessProgramExercises}
              onHandleEditProgram={handleEditProgram}
              onHandleDeleteProgram={handleDeleteProgram}
            />
          </Box>
          <Box m={3} mt={6}>
            <Form
              program={program}
              hideForm={hideForm}
              onHandleInputProgramChange={handleInputProgramChange}
              onHandleSaveProgram={handleSaveProgram}
              onHandleShowForm={handleShowForm}
            />
          </Box>
        </Box>
      </Grid>
    </Main>
  );
};
